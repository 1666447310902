<template>
    <div class="card card-custom gutter-b">
        <div class="card-header d-flex justify-content-between">
            <div class="card-title">
                <h3 class="card-label">Chi tiết buổi kiểm tra {{ listTest ? typeDefine[listTest.type] + ' ' + listTest.numerical_order : '' }} {{ listTest && listTest.classroom ? ' - ' + listTest.classroom.name : ''}}</h3>
            </div>
        </div>

        <div class="card-body">
            <!--begin::Example-->
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                        <el-input placeholder="Nhập tên, SĐT, mã HV" v-model="query.keyword" @input="search"></el-input>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <el-select @input="search" v-model="query.result" filterable class="w-100" placeholder="Chọn kết quả"
                                   clearable>
                            <el-option
                                v-for="item in results"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"

                            >
                                <span style="float: left">{{ item.name }}</span>
                            </el-option>
                        </el-select>
                    </div>
                </div>

            </div>

            <div class="example mb-10">
                <div class="example-preview table-responsive">
                    <table class="table table-hover table-bordered table-vertical-center b-table">
                        <thead>
                        <tr>
                            <th scope="col">Học viên</th>
                            <th scope="col">Số điện thoại</th>
                            <th scope="col">Speaking</th>
                            <th scope="col">Listening</th>
                            <th scope="col" v-if="center_id == 530">Reading & Writing</th>
                            <th scope="col" v-if="center_id != 530">Reading</th>
                            <th scope="col" v-if="center_id != 530">Writing</th>
                            <th scope="col" v-if="center_id != 530">Vocab</th>
                            <th scope="col">Overall</th>
                            <th scope="col">Kết quả</th>
                            <th scope="col">Hành động</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="students.length === 0 && !loading" class="text-center">
                            <td :colspan="center_id == 530 ? '8' : '10'">
                            Không có dữ liệu.
                            </td>
                        </tr>
                        <tr v-for="(item, index) in students" :key="index">
                            <td>{{ item.user ? item.user.id + 100000 : '' }} - {{ item.user ? item.user.name : '' }}</td>
                            <td>{{ item.user ? item.user.phone : '' }}</td>
                            <td>
                                {{ item.schedule_mark_test ? convertJson(item.schedule_mark_test.test_marks).speaking_score : '' }}
                            </td>
                            <td>
                                {{ item.schedule_mark_test ? convertJson(item.schedule_mark_test.test_marks).listening_score : '' }}
                            </td>
                            <td v-if="center_id == 530">
                                {{ item.schedule_mark_test ? convertJson(item.schedule_mark_test.test_marks).reading_and_writing_score : '' }}
                            </td>
                            <td v-if="center_id != 530">
                                {{ item.schedule_mark_test ? convertJson(item.schedule_mark_test.test_marks).reading_score : '' }}
                            </td>
                            <td v-if="center_id != 530">
                                {{ item.schedule_mark_test ? convertJson(item.schedule_mark_test.test_marks).writing_score : '' }}
                            </td>
                            <td v-if="center_id != 530">
                                {{ item.schedule_mark_test ? convertJson(item.schedule_mark_test.test_marks).vocabulary_score : '' }}
                            </td>
                            <td>
                                {{ item.schedule_mark_test ? convertIntToFloat(item.schedule_mark_test.avg_score) : '' }}
                            </td>
                            <td >
                                <span v-if="item.schedule_mark_test && item.schedule_mark_test.avg_score != null" class="p-2 rounded text-white" :class="item.is_pass =='Pass' ? 'bg-success' : 'bg-danger'">
                                   {{ item.is_pass }}
                                </span>
                                <span v-else></span>
                            </td>
                            <td>
                                <a v-if="currentUser.user.permission.includes(permission.NHAP_DIEM_KIEM_TRA)" title="Nhập điểm" class="btn btn-icon mr-2 btn-outline-primary"
                                   @click="showEnterPoint(item)">
                                    <i class="fas fa-pen-nib"></i>
                                </a>

                                <a v-if="currentUser.user.permission.includes(permission.NHAP_DIEM_KIEM_TRA)" title="Lịch sử test" href="javascript:"
                                   class="btn btn-icon btn-violet" @click="showTestHistory(item)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-alarm" viewBox="0 0 16 16">
                                        <path d="M8.5 5.5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9z"/>
                                        <path d="M6.5 0a.5.5 0 0 0 0 1H7v1.07a7.001 7.001 0 0 0-3.273 12.474l-.602.602a.5.5 0 0 0 .707.708l.746-.746A6.97 6.97 0 0 0 8 16a6.97 6.97 0 0 0 3.422-.892l.746.746a.5.5 0 0 0 .707-.708l-.601-.602A7.001 7.001 0 0 0 9 2.07V1h.5a.5.5 0 0 0 0-1zm1.038 3.018a6 6 0 0 1 .924 0 6 6 0 1 1-.924 0M0 3.5c0 .753.333 1.429.86 1.887A8.04 8.04 0 0 1 4.387 1.86 2.5 2.5 0 0 0 0 3.5M13.5 1c-.753 0-1.429.333-1.887.86a8.04 8.04 0 0 1 3.527 3.527A2.5 2.5 0 0 0 13.5 1"/>
                                    </svg>
                                </a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div>
            <el-dialog :title="titleEnterPoint" :visible.sync="dialogEnterPoints">
                <div class="col-12 row">
                    <div class="col-6 mt-4">
                        <label for="">Lần kiểm tra</label>
                        <el-select v-model="form.type" filterable
                                   @change="filterDataType"
                                   class="w-100"
                                   placeholder="Lần kiểm tra"
                                   clearable>
                            <el-option
                                v-for="i in typeListTest"
                                :key="i"
                                :label="'Lần thi ' + (i)"
                                :value="i">
                                <span style="float: left">Lần thi {{ i }}</span>
                            </el-option>
                        </el-select>
                    </div>
                    <div class="col-6 mt-4">
                        <label for="">Điểm nghe </label>

                        <el-select v-model="form.listening" clearable filterable class="w-100" :placeholder="`Nhập điểm 0.0 - ` + scoreMax">
                            <el-option v-for="(item, index) in scores"
                                       :value="item"
                                       :key="index"
                                       :label="item"
                            >
                                <span>{{item}}</span>
                            </el-option>
                        </el-select>
                    </div>
                    <div class="col-6 mt-4">
                        <label for="">Điểm nói</label>
<!--                      Speaking-->
                      <el-select v-model="form.speaking" filterable clearable class="w-100" :placeholder="`Nhập điểm 0.0 - ` + scoreMax">
                            <el-option v-for="(item, index) in scores"
                                       :value="item"
                                       :key="index"
                                       :label="item"
                            >
                                <span>{{item}}</span>
                            </el-option>
                        </el-select>
                    </div>
                    <div class="col-6 mt-4" v-if="center_id == 530">
                        <label for="">Điểm đọc và viết</label>

                        <el-select v-model="form.reading_and_writing" clearable filterable class="w-100" :placeholder="`Nhập điểm 0.0 - ` + scoreMax">
                            <el-option v-for="(item, index) in scores"
                                       :value="item"
                                       :key="index"
                                       :label="item"
                            >
                                <span>{{item}}</span>
                            </el-option>
                        </el-select>
                    </div>
                    <div class="col-6 mt-4" v-else>
                        <label for="">Điểm viết</label>

                        <el-select v-model="form.writing" filterable clearable class="w-100" :placeholder="`Nhập điểm 0.0 - ` + scoreMax">
                            <el-option v-for="(item, index) in scores"
                                       :value="item"
                                       :key="index"
                                       :label="item"
                            >
                                <span>{{item}}</span>
                            </el-option>
                        </el-select>
                    </div>
                    <div class="col-6 mt-4" v-if="center_id != 530">
                        <label for="">Điểm đọc </label>

                        <el-select v-model="form.reading" filterable class="w-100" clearable :placeholder="`Nhập điểm 0.0 - ` + scoreMax">
                            <el-option v-for="(item, index) in scores"
                                       :value="item"
                                       :key="index"
                                       :label="item"
                            >
                                <span>{{item}}</span>
                            </el-option>
                        </el-select>
                    </div>
                    <div class="col-6 mt-4" v-if="center_id != 530">
                        <label for="">Điểm từ vựng ngữ pháp </label>

                        <el-select v-model="form.vocabulary" filterable clearable class="w-100" :placeholder="`Nhập điểm 0.0 - ` + scoreMax">
                            <el-option v-for="(item, index) in scores"
                                       :value="item"
                                       :key="index"
                                       :label="item"
                            >
                                <span>{{item}}</span>
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogEnterPoints = false">Huỷ</el-button>
                    <el-button type="primary" class="text-white" @click="submit">Xác nhận</el-button>
                </span>
            </el-dialog>
            <el-dialog :title="titleHistory" :visible.sync="dialogHistory">
                <table class="table table-hover table-bordered table-vertical-center b-table">
                    <thead>
                        <tr>
                            <th>Lần test</th>
                            <th>Speaking</th>
                            <th>Listening</th>
                            <th v-if="center_id != 530">Reading</th>
                            <th v-if="center_id != 530">Writing</th>
                            <th v-if="center_id != 530">Vocab </th>
                            <th v-else>Reading & Writing </th>
                            <th>Thời gian cập nhật </th>
                            <th>Người cập nhật </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in dataHistory" :key="index">
                            <td>{{ item.order }}</td>
                            <td>{{ convertJson(item.test_mark).speaking_score }}</td>
                            <td>{{ convertJson(item.test_mark).listening_score }}</td>
                            <td v-if="center_id != 530">{{ convertJson(item.test_mark).reading_score }}</td>
                            <td v-if="center_id != 530">{{ convertJson(item.test_mark).writing_score }}</td>
                            <td v-if="center_id != 530">{{ convertJson(item.test_mark).vocabulary_score }}</td>
                            <td v-else>{{ convertJson(item.test_mark).reading_and_writing_score }}</td>
                            <td>{{ item.updated_at |formatDateTimeVietnam}}</td>
                            <td>{{ item.user_update ? (item.user_update.id + 100000) + ' - ' + item.user_update.name : '' }}</td>
                        </tr>
                    </tbody>
                </table>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import {
    GET_HISTORY_TEST,
    GET_ORDER,
    GET_STUDENT_BY_CLASS,
    LIST_TYPE_TEST_EXAM,
    POST_SCORE_EXAM,
    GET_LIST_TEST_DETAIL
} from "../../../core/services/store/exam/exam.module";
import {mapGetters} from "vuex";
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";

export default {
    name: "ExamDetail",
    data() {
        return {
            page: 1,
            last_page: 1,
            query: {
                keyword: '',
                result: ''
            },
            students: [],
            is_disable_search: false,
            results: [
                {
                    id: 1,
                    name: 'Pass'
                },
                {
                    id: 2,
                    name: 'Fail'
                }
            ],
            class_id: this.$route.params.id,
            dialogEnterPoints: false,
            dialogHistory: false,
            typeListTest: 0,
            scores: [],
            scoreMax: '',
            form: {
                type: '',
                listening: '',
                reading_and_writing: '',
                reading: '',
                student_id: '',
                list_test_id: '',
                writing: '',
                speaking: '',
                vocabulary: ''
            },
            titleEnterPoint: '',
            titleHistory: '',
            dataHistory: [],
            center_id: '',
            listTest: {},
            typeDefine: {
                mid: 'Thi giữa khóa',
                mini: 'Mini Test',
                unit: 'Unit Test',
                last: 'Cuối khóa',
            },
            total: 0,
            loading: false
        }
    },
    computed: {
        ...mapGetters(["currentUser"]),

    },
    mounted() {
        this.getListStudents();
        this.getListTest();
        this.$store.dispatch(SET_BREADCRUMB, [
          {title: "Danh sách buổi kiểm tra", route: 'exam-index'},
          {title: "Chi tiết buổi kiểm tra"}
        ]);
    },
    methods: {
        search() {
            this.getListStudents();
        },
        clickCallback(obj) {
            this.page = obj;
            this.pushParamsUrl();
            this.getListStudents()
        },
        pushParamsUrl() {
            this.$router.push({
                path: '', query: {
                    page: this.page,
                    ...this.query
                }
            })
        },
        getListStudents()
        {
            this.$store.dispatch(GET_STUDENT_BY_CLASS, {
                page: this.page,
                class_id: this.class_id,
                list_test_id: this.$route.query.list_test_id,
                keyword: this.query.keyword,
                result: this.query.result,
                type: 'exam',
            }).then((res) => {
                this.students = res.data;
                this.last_page = res.pagination.last_page;
                this.total = res.pagination.total;
            });
        },
        convertJson(string)
        {
            return JSON.parse(string);
        },
        getTypeListTest()
        {
            this.$store.dispatch(LIST_TYPE_TEST_EXAM, {}).then((res) => {
                this.typeListTest = res.data;
            });
        },
        submit()
        {
            if (!this.form.listening && !this.form.reading && !this.form.reading_and_writing && !this.form.speaking && !this.form.vocabulary && !this.form.writing) {
                this.noticeMessage('error', 'Thất bại', 'Bắt buộc nhập ít nhất 1 điểm !');
                return;
            }

            this.$store.dispatch(POST_SCORE_EXAM, this.form).then((res) => {
                this.noticeMessage('success', 'Thành công', 'Nhập điểm thành công!');
                this.getListStudents();
                this.dialogEnterPoints = false;
                this.form = {
                    type: '',
                    listening: '',
                    reading_and_writing: '',
                    reading: '',
                    student_id: '',
                    list_test_id: '',
                    writing: '',
                    speaking: '',
                    vocabulary: ''
                };
            });
        },
        showEnterPoint(item) {
            this.form.student_id = item.user_id;
            this.form.list_test_id = this.$route.query.list_test_id;
            this.dialogEnterPoints = true;
            this.titleEnterPoint = `Nhập điểm kiểm tra ${item.user.name}`;
            // this.scores = Array.from({ length: (item.course.course_type.score_scale * 2) + 1 }, (_, i) => (i * 0.5).toFixed(1))
            this.getOrder(item.user_id, this.$route.query.list_test_id)
            this.getHistory(item);
        },
        showTestHistory(item)
        {
            this.center_id = item.schedule_mark_test && item.schedule_mark_test.schedule_list_test ? item.schedule_mark_test.schedule_list_test.center_id : '';
            this.dialogHistory = true;
            this.titleHistory = `Lịch sử test của học viên ${item.user.name}`;
            this.getHistory(item);
        },
        getHistory(item) {
            this.$store.dispatch(GET_HISTORY_TEST, {student_id: item.user_id, list_test_id: this.$route.query.list_test_id}).then((res) => {
                this.dataHistory = res.data;
            });
        },
        getOrder(user_id, list_test_id)
        {
            this.$store.dispatch(GET_ORDER, {student_id: user_id, list_test_id: list_test_id}).then((res) => {
                let data = (res.data ? res.data.order : 0) + 1;
                this.typeListTest = data;
                this.form.type = data;
            });
        },
        getListTest()
        {
            this.$store.dispatch(GET_LIST_TEST_DETAIL, {id: this.$route.query.list_test_id}).then((res) => {
                this.listTest = res.data;
                this.center_id = res.data.center_id;
                this.scores = Array.from({ length: (res.data.course.score_scale * 2) + 1 }, (_, i) => (i * 0.5).toFixed(1))
                let count = Object.values(this.scores).length;
                this.scoreMax = count ? this.scores[count -1] : '';

            });
        },
        filterDataType(type)
        {
            let data = this.dataHistory.filter((d) => d.order == type)[0];
            if (data)
            {
                let testMark = this.convertJson(data.test_mark);
                this.form.listening = testMark.listening_score;
                this.form.reading_and_writing = testMark.reading_and_writing_score;
                this.form.reading = testMark.reading_score;
                this.form.speaking = testMark.speaking_score;
                this.form.vocabulary = testMark.vocabulary_score;
                this.form.writing = testMark.writing_score;
                return;
            }
            this.form.listening = '';
            this.form.reading_and_writing = '';
            this.form.reading = '';
            this.form.speaking = '';
            this.form.vocabulary = '';
            this.form.writing = '';

        },
        convertIntToFloat(score) {
            return score != null ? (score * 1.0).toFixed(1) : null;
        }
    }
}
</script>

<style scoped>
    .btn-violet {
        border: 1px solid rebeccapurple;
        color: rebeccapurple;
    }
</style>